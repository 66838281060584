@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes moveAndRotate {
    0% {
      transform: translateX(0) rotate(0deg);
    }
    100% {
      transform: translateX(calc(100vw - 16px)) rotate(360deg);
    }
  }

 @keyframes moveAndRotateLeftToRight {
  0% {
    transform: translateX(-100vw) rotate(0deg); /* Start from off-screen left */
  }
  100% {
    transform: translateX(100vw) rotate(360deg); /* Move to off-screen right */
  }
}

@keyframes moveAndRotateRightToLeft {
  0% {
    transform: translateX(100vw) rotate(0deg); /* Start from off-screen right */
  }
  100% {
    transform: translateX(-100vw) rotate(360deg); /* Move to off-screen left */
  }
}

@keyframes fall {
from {
    transform: translateY(-100vh); /* Start from off-screen top */
}
to {
    transform: translateY(100vh); /* End at off-screen bottom */
}
}

@keyframes spinY {
from {
    transform: rotateY(0deg);
}
to {
    transform: rotateY(1800deg); /* 360deg * 5 = 1800deg */
}
}

/* Class for spinning animation */
.spin-animation {
    animation: spinY 10s ease-in-out infinite; /* Smooth easing and infinite loop */
    transform-style: preserve-3d; /* Ensures smooth 3D transformation */
    backface-visibility: hidden; /* Hide the back face during rotation */
}

/* Style for the falling blocks */
.falling-block {
    position: absolute;
    top: 0;
    z-index: 1; /* Ensure the blocks appear above other content */
    pointer-events: none; /* Ensures the blocks do not interfere with other elements */
}

/* Style for the animated block */
.animated-block {
  position: fixed;
  top: 50%;
  left: 0; /* Default starting position (will be updated in JavaScript) */
  width: 12px; /* Default size */
  height: 12px; /* Default size */
  z-index: 1;
  pointer-events: none; /* Ensures the block does not interfere with other elements */
}

.background-image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/src/images/duck-mountain.JPG');
  background-size: cover; /* Equivalent to object-fit: cover; */
  background-position: center;
  background-repeat: no-repeat;
  aspect-ratio: 16/9; /* Use the appropriate aspect ratio */
  z-index: -1;
}